/* eslint-disable @next/next/no-img-element */
import type { NextPage } from 'next'

import { Row, Col, Card, Empty, Typography } from '@douyinfe/semi-ui'
import { IllustrationNotFound } from '@douyinfe/semi-illustrations';

import { useIntl } from 'react-intl'

import GlobalLayout from 'components/globalLayout'
import { useSmallScreen } from 'hooks/useResponsive';

const NotFoundPage: NextPage = () => {
    const intl = useIntl()
    const isSmallScreen = useSmallScreen()

    return (
        <GlobalLayout title='Page not found - MFPapers'>
            <Row style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: 600,
                height: 'calc(100vh - 145px)',
                backgroundColor: '#0093E9',
                backgroundImage: "radial-gradient( circle 588px at 31.7% 40.2%,  rgba(225,200,239,1) 21.4%, rgba(163,225,233,1) 57.1% )",
                justifyContent: 'center'
            }}>
                <Col md={24} lg={{ span: 14, offset: 5 }} >
                    <Card
                        style={{
                            margin: '0 10px',
                            cursor: 'default',
                            textAlign: 'center'
                        }}
                        shadows='always'
                    >
                        <Empty
                            image={<IllustrationNotFound style={{ width: isSmallScreen ? 300 : 400, height: isSmallScreen ? 300 : 400 }} />}
                            title={
                                <Typography.Title style={{ color: 'var(--semi-color-info)' }}>
                                    {intl.formatMessage({ defaultMessage: "Page Not Found", id: 'Mz/gsa' })}
                                </Typography.Title>
                            }
                        />
                        <br></br>
                        <Typography.Text type='secondary' style={{ fontSize: 20 }}>
                            {intl.formatMessage({ defaultMessage: "The page you're looking for cannot be found.", id: 'jl34xZ' })}
                        </Typography.Text>
                    </Card>
                </Col>
            </Row>
        </GlobalLayout>
    );
}

export default NotFoundPage
